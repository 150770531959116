<template>
  <div class="w-full">

    <div class="flex flex-col text-primary divide-y divide-gray">

      <div class="lb-navigation">

        <div class="order-2 text-left col-span-2">
          <div class="flex items-center lg:hidden">
            <UIMobileMenuButton />

            <UIMobileSearchButton />
          </div>
        </div>

        <Logo class="lb-nav-logo order-3 col-span-2"/>

        <LayoutNavigationMenu class="order-3 flex-1" v-if="isShowMenu" />

        <LayoutNavigationUserContentArea class="lg:!hidden" />

      </div>

      <SearchInput class="w-full flex lg:hidden" v-if="isShowMobileSearch" is-expandabled="true"/>
    </div>
  </div>
</template>

<script setup>
const {isShowMenu, isShowMobileSearch} = useHelper()
</script>